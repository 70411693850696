<div class="encabezado">
    <div class="contenedor-btn-ver-frase" fxLayout="column" fxLayoutAlign="center center">


        <div fxLayout="column" fxLayoutAlign="center center" style="flex: 1;text-align: center;">
            <div style="margin-bottom: 21px;">
                <p style="font-size: 26px;color: #479b25;font-weight: 500;">¡Muchas gracias!</p>
                <p class="t-gray" style="max-width: 650px;text-align: center;">
                    Con tu aporte y bendición puedo continuar con mi trabajo. Prometo entrega.
                </p>
            </div>

            <div>
                <p class="t-gray" style="max-width: 650px;text-align: center;">
                    Comparte tu aporte para seguir colaborando:
                </p>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="icon-social cursor-pointer" src="assets/iconos/Facebook.png" alt="" style="margin-right: 21px;" (click)="goFacebook()">
                    <img class="icon-social cursor-pointer" src="assets/iconos/Instagram.png" alt="" (click)="goInstagram()">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" style="height: 64px;background: #479b25;width: 100%;">
            <button class="bg-primary t-white mb-0 border-r-18" mat-button type="button" fxLayout="row"
                fxLayoutAlign="center center" (click)="goUrl()">
                <span class="material-icons">
                    arrow_forward
                </span>
                POSTULAR A ESCUELA RETORNANDO A LA FUENTE
            </button>
        </div>
    </div>
</div>