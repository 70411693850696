<div class="encabezado">
    <div class="contenedor-btn-ver-frase" fxLayout="column" fxLayoutAlign="center center">

        <div fxLayout="column" fxLayoutAlign="center center" style="flex: 1;">
            <img class="pratyanLogo" src="assets/boton.png" alt="" style="margin-bottom: 12px;">

            <p style="font-size: 21px;color: #479b25;font-weight: 500;">Micro Satsangs</p>

            <p class="t-gray" style="max-width: 650px;text-align: center;">
                Para acceder gratis a <span style="font-weight: 500;">Micro Satsangs</span>, más de mil frases para
                Despertar y más de dos mil
                imágenes para Contemplar de Sagrada Naturaleza, envíame un email a naturaleza.aqui.ahora@gmail.com
                contándome por qué quieres acceder. Te respondo pronto.
            </p>

            <form class="form-login mt-4" [formGroup]="loginForm" (ngSubmit)="login()" fxLayout="column"
                fxLayoutAlign="center center">


                <mat-form-field class="mb-6 w-full">
                    <mat-label>Usuario</mat-label>
                    <input matInput formControlName="usuario" required />
                    <!-- <mat-error>
                        {{ getErrorMessage(loginForm.get("usuario")) }}
                    </mat-error> -->
                </mat-form-field>

                <mat-form-field class="mb-6 w-full">
                    <mat-label>Contraseña</mat-label>
                    <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" required />

                    <button type="button" mat-icon-button matSuffix (click)="showPassword = !showPassword" [matTooltip]="
                      (showPassword ? 'Ocultar' : 'Mostrar') + ' contraseña'">
                        <mat-icon>
                            {{ showPassword ? "visibility" : "visibility_off" }}
                        </mat-icon>
                    </button>
                    <!-- <mat-error>
                        {{ getErrorMessage(loginForm.get("password")) }}
                    </mat-error> -->
                </mat-form-field>

                <div>
                    <button class="bg-primary t-white mb-4 border-r-18" mat-raised-button type="submit"
                        [disabled]="loginForm.invalid" fxLayout="row" fxLayoutAlign="center center">
                        Ingresar
                    </button>
                </div>
            </form>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" style="height: 64px;background: #479b25;width: 100%;">
            <button class="bg-primary t-white mb-0 border-r-18" mat-button type="button" fxLayout="row"
                fxLayoutAlign="center center" (click)="goUrl()">
                <span class="material-icons">
                    arrow_forward
                </span>
                POSTULAR A ESCUELA RETORNANDO A LA FUENTE
            </button>
        </div>

    </div>
</div>