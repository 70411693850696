import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authRoutes } from './auth/auth.routes';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  ...authRoutes,
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./main/main.module").then((m) => m.MainModule),
  },
  // { path: "frase-de-hoy", component: FraseDelDiaComponent },
  // { path: "", component: InicioComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
