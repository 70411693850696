import { Routes } from "@angular/router";
import { GraciasComponent } from "./gracias/gracias.component";
import { LoginComponent } from './login/login.component';

export const authRoutes: Routes = [
  {
    path: "login",
    // canActivate: [NotLoggedInGuard],
    component: LoginComponent,
  },
  {
    path: "gracias",
    component: GraciasComponent
  },
];
