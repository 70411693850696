import { Component } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { LayoutService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pratyan';
  fecha: string = moment().format('YYYY');

  constructor(
    private router: Router,
    public layoutService: LayoutService
  ) {}

  goInicio() {
    this.router.navigateByUrl(`/`)
  }

  goFraseDelDia() {
    // this.router.navigateByUrl(`/frase-de-hoy`)
  }
}
