import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credencial: any = {
    usuario: 'Upanishad',
    password: 'naturaleza'
  }
  // Formulario de login.
	loginForm: FormGroup;
	pay: FormGroup;

	// Para controlar si el input de password debe ser de tipo `password` o `text`.
	showPassword = false;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
			usuario: ["", [Validators.required]],
			password: ["", [Validators.required]],
		});
  }

  login() {
    if (this.credencial.usuario === this.loginForm.value.usuario && this.credencial.password === this.loginForm.value.password) {
      localStorage.setItem("access_token", `S8hSKhkh915jkasd97dkjSKhdk08`)
      this.router.navigate(["/frase/hoy"]);
    } else {
      this.openSnackBar();
    }
  }

  openSnackBar() {
    this._snackBar.open("Credencial inválida", null, {
      duration: 3000,
    });
  }

  goUrl() {
    window.location.href = 'https://www.retornandoalafuente.yoga/';
  }

}
