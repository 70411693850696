import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gracias',
  templateUrl: './gracias.component.html',
  styleUrls: ['./gracias.component.scss']
})
export class GraciasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goFacebook() {
    window.location.href = 'https://www.facebook.com/retornandoalafuente/';
  }

  goInstagram() {
    window.location.href = 'https://www.instagram.com/retornandoalafuente/';
  }

  goUrl() {
    window.location.href = 'https://www.retornandoalafuente.yoga/';
  }

}
